<template>
  <div>
    <h2 class="mb-4">Профиль</h2>

    <v-sheet class="pa-4" elevation="3">
      <v-row class="props-list">
        <v-col md="2">ID</v-col>
        <v-col md="10">{{ user.id }}</v-col>

        <v-col md="2">Email</v-col>
        <v-col md="10">{{ user.email }}</v-col>

        <v-col md="2">Имя</v-col>
        <v-col md="10">{{ user.name }}</v-col>

        <v-col md="2">Фамилия</v-col>
        <v-col md="10">{{ user.lastName }}</v-col>

        <v-col md="2">Роль</v-col>
        <v-col md="10">{{ user.role }}</v-col>
      </v-row>

      <div class="text-right">
        <v-btn color="accent" :to="{ name: 'users-id', params: { id: user.id } }">Редактировать</v-btn>
      </div>
    </v-sheet>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
